.spot-list {
  margin: 20px 0px;
  padding-left: 0px;
  column-count: 3;
  column-width: 200px;
  li {
    list-style: none;
    text-align: left;
    margin: 10px 0px;
    width: 100%;
    text-align: center;
    background-color: #f8f8f8;
    border: 1px solid #55555552;
    color: rgba(0, 0, 0, 0.701);
    border-radius: 5px;
    padding: 8px;
    margin-top: 0px;

    a, .spot-list-card {
      padding-left: 0;
      color: rgba(0, 0, 0, 0.701);
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.spot-list-all {
  margin: 0px;
  margin-top: 20px;
  padding-left: 0px;
  display: grid;
  grid-template-columns: 350px 350px 350px;
  justify-content: space-around;

  @media(max-width: 1200px){
    grid-template-columns: 350px 350px;
  }

  @media(max-width: 800px){
    grid-template-columns: 100%;
  }

  a, .spot-list-card {
    list-style: none;
    max-width: 100%;
    text-align: left;
    margin: 10px 0px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 5px;
    padding: 8px 1px;
    margin-top: 0px;
    font-size: 1em;
    display: flex;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    position: relative;

    img{
      width: 140px;
      height: 80px;
      margin-left: 10px;
      border-radius: 15px;
      top: 20px;
      left: 25px;
    }

    img:nth-of-type(2){
      margin: 0;
      height: fit-content;  
    }

    @media (max-width: 1000px) {
      max-width: 100%;
    }

    .spotCardContent {
      padding-left: 0;
      color: rgb(35, 35, 35);
      font-weight: 350;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      padding: 0 20px;
      width: calc(100% - 140px);

      @media (max-width: 1000px) {
        font-size: 1em;
      }
    
    }
  }
}

.brand-label-black {
  background-color: #000;
  color: #fff;
  padding: 3px 20px;
  border-radius: 3px;
  font-size: 1.1em;
  font-weight: 400;
}

.spot-detail {
  color: #bbb;
  font-weight: 400;
  font-size: 0.9em;
}

.brand-label-department {
  margin-top: 20px;
  color: black;
  font-weight: 700;
  padding: 3px 20px;
  padding-left: 10px;
  font-size: 1.1em;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 8px 20px;
  margin-bottom: 10px;
}

.spot-orientation {
  text-align: center;
  font-weight: 700;
  margin-bottom: 0;
}

.select-list{
  margin-top: 0px;
  justify-content: flex-start;
  padding: 0px 0;

  @media (max-width: 1000px) {
    padding: 0px;
    margin-top: 0px;
  }

  .select-list-label {
    margin-right: 10px;
    margin-top: 13px;
  }

  div:nth-child(1){
    margin-left: -5px;
  }

  .ant-select-arrow{
    margin-right: 19px;
  }
}

.filter-container{
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  position: relative;
  margin-bottom: 15px;
  @media(max-width: 999px){
    grid-template-columns: 1fr;
    grid-template-rows: 3fr ;
    justify-items: start;
    margin: 15px 0 30px 0;
    gap: .5rem;
    padding: 0 30px;

    .select-list{
      width: 100%;

      label{
        width: 100px;;
      }
    }

    .switch-wrap{
      margin: 0 auto;
    }
  }

  .display-selector{
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    color: unset;
  }

  .primary-switch{
    margin-top: 0;
  }

  .filter{
    margin-top: 15px;
    @media(min-width: 1000px){
    // position: absolute;
    // right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -30px !important;
    }
    span{
      margin-right: 10px;
    }

    @media(max-width: 1000px){
      display: grid;
      width: 100%;

      .ant-select{
        width: 95% !important;
        margin: 10px 0px;
        margin-left: 0px !important;
      }
    }

    // .map-switch{
    //   margin: 0 0 0 20px;
    //   @media (max-width: 999px) {
    //     margin: 0;
    //   }
    // }

    .switch-container{
      display: flex;
      align-items: center;
      width: 100%;
      justify-items: center;
      

      .map-switch{
        margin: 0 0 0 10px;
        justify-self: start;
        width: 100%;
      }
      .switch-text{margin: 0 0 0 20px}
      .sub-options-col{display: flex}
      .switch-plans{
        position: static;
        margin: 0;
        width: 100%;
        justify-content: space-around !important;
        align-items: center;

        span{
          font-size: 1em;
          font-weight: 400;
          color: unset;
        }
      }
      .primary-switch{
        margin: 0;
        margin-bottom: 10px;
      }

      #select-country-label, #select-oritentation-label{
        grid-row-start: 1;
      }

      @media (max-width: "999px") {
        height: 100%;
        display: grid;
        width: 100%;
        justify-items: center;
        align-items: center;
        grid-template-columns: 50% 50% !important;
        grid-auto-rows: 50%;

        
        display: flex;
        flex-direction: column-reverse;


        .filter-container .filter-span{
          margin-left: 10px;
        }

        #select-oritentation-label{
          display: flex;
          align-self: start;
          justify-content: flex-start;
          margin-left: 10px;
        }

        .sub-options-col{flex-direction: column;}

       .switch-plans{
        position: static;
        margin: 0;
        width: 100%;
        justify-content: space-around !important;
        align-items: center;
        height: 30px;
        margin-bottom: 20px;
       }
      }
      // flex-direction: row !important;
      display: grid;
    grid-template-columns: repeat( 3, 1fr);   
    }

    .ant-switch-checked{
      background-color: #435881 !important;
    }

     
  }
}

.switch-separator{
  height: 70%;
  width: 1px;
  background: #dadada;
}

.switch-container{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.spots-type-list{
  padding: 0px 0px;
  display: flex;
  margin-top: -39px;
  margin-bottom: 30px;
  
  div {
    width: 100%;
    padding: 5px;
  }

  @media (max-width: 1000px) {
    padding: 0px;
    margin-top: 0px;
  }
}

.countryDataContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.countryDataText{
  font-size: 18px;
  font-family: Poppins;
  color: rgb(100, 100, 100);
  text-align: center;

  strong{
    color: #000;
  }

  @media (max-width: 400px) {
    font-size: 16px;
    margin-top: 1rem;
  }
}

.noCamWenFilter {
  margin: 0 0 0 20px;

  @media (max-width: 1000px) {
    margin: 0;
    text-align: center;
  }
}