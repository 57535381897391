.cam-list-box {
  @media (max-width: 1000px) {
    overflow-y: scroll;
    max-height: 450px;
  }
}

.camara-cover-box {
  display: inline-flex;
}

.camara-cover {
  height: 180px;
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  margin: 10px 0px;
  cursor: pointer;

  @media (max-width: 1000px) {
    height: 130px !important;
  }

  h2 {
    font-size: 1.4em;
    position: absolute;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 40%;
  }
}

.cover-overlay {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #01223fad;
  border-radius: 10px;
}

.navbar-cities {
  margin-top: -15px;
  margin-bottom: 5px;
  display: flex;
  overflow-x: scroll !important;
  a {
    background-color: #425781;
    color: #fff;
    padding: 5px 20px;
    border-radius: 3px;
    font-size: 1.1em;
    font-weight: 400;
    margin-right: 15px;
  }

  a:hover,
  .active {
    color: #425781 !important;
    border: 1px solid #425781 !important;
    background-color: transparent !important;
    text-decoration: none;
  }
}
