.searchbar-screen-container {
  height: 100vh;
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.searchbar-screen-container > div:first-child{
  display:flex;
  flex-direction: column;
  width: 100%;
  max-height: 60vh;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
}

.searchbar-container {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 30px 30px;
  border-bottom: 2px solid #d9d9d9;
}

.searchbar-close-button {
  border: none;
  width: 45px;
  margin-left: 30px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar-not-found-any-spot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-size: 16px;
}

.searchbar-option-spot-name {
  display: flex;
  font-family: 'Poppins';
  align-items: center;
}

.searchbar-option-spot-city {
  font-weight: 400;
  font-size: 14px;
}

.searchbar-close-button > svg {
  width: 23px !important;
  height: 23px !important;
  color: rgb(0, 73, 137);
}

.searchbar-input {
  width: 100%;
  color: rgb(53, 84, 130);
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 73, 137) !important;
}

.searchbar-list-container {
  width: 100%;
  overflow-y: scroll;
  padding: 0 40px;
}

.searchbar-option-country {
  width: 100%;
  border-bottom: 2px solid rgb(239, 239, 239);
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  font-weight: 500;
  padding: 10px 0;
}

.searchbar-option-spot-list {
  display: flex;
  flex-wrap: wrap;
}

.searchbar-option-spot {
  display: flex;
  width: 50%;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #f6f6f6;
  padding: 10px 0;
  color: #000;
}

.searchbar-option-spot:hover {
  text-decoration: none;
  color: #000;
}



.searchbar-option-spot-cam {
  border-radius: 7px;
  width: 30px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; 
}

.searchbar-option-spot p { margin: 0; }

.searchbar-option-spot-cam img { margin: 0 !important; }

@media(max-width: 1000px) {
  .searchbar-screen-container > div:first-child { height: 100vh; max-height: 100vh; padding-bottom: 70px; }
  .searchbar-option-spot, .searchbar-option-spot p { width: 100%; }
  .searchbar-option-spot { align-items: start;}
  .searchbar-option-spot-name {
    display: flex;
    flex-direction: column;
  }

  .searchbar-option-spot p, .searchbar-option-spot-city { 
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    margin: 0 !important;
  }

  .searchbar-option-spot-city span { display: none; }

  .searchbar-option-country > svg {
    width: 23px !important;
    height: 23px !important;
  }

  .searchbar-close-button{
    margin-left: 15px;
    padding: 0;
    width: auto;
  }

  .searchbar-container { padding: 30px 15px; }
  .searchbar-list-container { padding: 0 15px; }
}