.ad-popup-container{ 
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  background: rgba(66, 87, 129, 0.4);
}

.ad-popup{
  background: #fff;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 360px; */
  /* min-height: 500px; */
  flex-direction: column;
}

.ad-popup-button{
  width: 320px;
  height: 45px;
  background-color: #000;
  font-weight: bold;
  color: #fff;
  border-radius: 8px;
  border: none;
} 

.ad-popup iframe {
  display: block !important;
  border-radius: 8px;
}

.ad-popup-ad-container {
  min-height: 480px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}