@keyframes slide-in-up-all-size {
  from { transform: translateY(100%) }
}

@keyframes slide-in-up {
  from { transform: translateY(35%) }
}

@keyframes slide-out-down {
  to { transform: translateY(100%) }
}

.header-title {
  margin-top: -60px;
  margin-bottom: 2%;

  @media (max-width: 1000px) {
    margin-bottom: 5px !important;
  }
}

.brand-label-black {
  background-color: black;
  color: #fff;
  padding: 3px 20px;
  border-radius: 3px;
  font-size: 1.1em;
  font-weight: 400;
}

.m-t-20 {
  margin-top: 20px;
}

::-webkit-scrollbar-thumb {
  background: red;
}

#multispot {
  .arrow-icon-list {
    margin-top: 148px !important;
  }

  .scroll-button-down-initial {
    margin-top: 0px !important;
    top: 555px;
  }
}

.arrow-icon-list {
  width: 35px;
  height: 35px;
  z-index: 999;
  position: absolute;
  color: #ffffffe6;
  border-radius: 50%;
  background-color: #000000;
  padding: 10px;
  margin-top: 41px;
  cursor: pointer;

  @media (max-width: 1000px) {
    display: none;
  }
}

.icon-rigth {
  right: -3px;
}

.icon-left {
  left: -3px;
}

.spot-card-icon-right {
  margin-top: 4% !important;
  right: -3px !important;
}

.spot-card-icon-left {
  margin-top: 4% !important;
  left: -3px !important;
}

.link-highlight {
  font-weight: 700 !important;
}

.main-content {
  margin-top: 60px !important;
}

.display-grid {
  display: contents !important;
}

.box-helpper {
  margin-bottom: 100px;
}

.clean-search {
  position: absolute;
  right: 50px;
  top: 10px;
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
  z-index: 9999;
  color: #495057 !important;
  background-color: #fff;
}

.scroll-button-down {
  bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transform: rotate(90deg);
}

.scroll-button-down,
.scroll-button-top,
.scroll-button-top-initial,
.scroll-button-down-initial {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transform: rotate(90deg);

  @media (max-width: 1000px) {
    display: none !important;
  }
}

.scroll-button-down {
  bottom: 10px;
}

.scroll-button-down-initial {
  margin-top: 0px !important;
  top: 445px;
}

.scroll-button-top {
  top: 100px !important;
}

.scroll-button-top-initial {
  top: 0px !important;
}

.button-primary {
  text-align: center;
  width: 100%;
  border: 1px solid #435881;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 1em;
  color: #fff;
  padding: 8px;
  background-color: #435881;
  margin: 10px 0;
}

.icon-cam-container{
  background-color: #fabc41;
  width: 40px;
  height: 28px;
  position: absolute;
  top: 8px;
  left: 11px;
  border-top-left-radius: 13px;
  border-bottom-right-radius: 5px;
}

.spot-card-items .icon-cam-container{
  top: 10px;
  left: 10px;
}

.icon-cam-container .icon-cam{
  top:2px !important;
  left: 2px !important;
  width: 25px !important;
  height: 25px !important;
  padding: 0px !important;
  border-radius: 1px !important;
}

.icon-cam {
  margin-left: 5px !important;
  width: 20px !important;
  
}

.button-primary:hover:enabled {
  border: 1px solid #004989;
  color: #fff;
  background-color: #004989;
}

.button-outline {
  text-align: center;
  width: 100%;
  border: 1px solid #004989;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 1em;
  color: #004989;
  padding: 8px;
  background-color: #fff;
  margin: 10px 0;
}

.button-danger {
  text-align: center;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 0.8em;
  color: black;
  padding: 8px;
  background-color: #fff;
  margin: 10px 0;

  :hover {
    background-color: #f65d5d !important;
    color: #fff !important;
  }
}

.site-wrapper {
  padding-bottom: 100px;
}

.make-flex {
  display: flex !important;
  padding: 0px 0px !important;
  margin-top: 10px;
  margin-bottom: -7px;

  div:nth-child(1) {
    padding-left: 0px !important;
  }

  div:nth-child(2) {
    padding-right: 0px !important;
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}

.bold {
  font-weight: 700 !important;
}

.blur {
  pointer-events: none;
  filter: blur(4px);
}

.button-logout {
  @media (min-width: 1000px) {
    display: none !important;
  }
}

.delete-account {
  @media(max-width: 999px){
    margin-top: 80px;
  }
}

button:disabled,
button[disabled] {
  background-color: #d9dfe6;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #8da2c0;
}

.button-green {
  background-color: #5db24a !important;
  border: 1px solid #5db24a;
}

.countries-list {
  display: flex;
  margin-top: 10px;

  div {
    width: 115px;
    padding: 5px;
  }
}

.toggle-switch {
  --width: 30px;
  --height: 20px;
  --padding: 2px;
  --handle-size: calc(var(--height) - var(--padding) * 2);
  width: 100%;
  text-align: center;
  display: inline-block;
  outline-width: 0;
}

.toggle-switch > input {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
}

label {
  display: inline-flex !important;
  grid-template-columns: auto auto;
  column-gap: 10px;
}

.area {
  padding: 4px;
  margin: -4px;
}

.toggle-switch :active {
  outline-width: 0;
}

.background,
.handle {
  transition: all 0.1s ease;
}

.background {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  padding: 0 var(--padding);
  vertical-align: text-bottom;
  user-select: none;
  background-color: darkgray;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: background-color 0.15s ease;
}

.toggle-switch:focus .area {
  outline: 1px dotted gray;
}

.toggle-switch:active .area {
  outline-width: 0;
}

.toggle-switch:focus .background,
.area:hover .background {
  background-color: gray;
}

.handle {
  width: var(--handle-size);
  height: var(--handle-size);
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.handle:hover {
  background-color: white;
}

input:checked + label .area .background {
  background-color: lightgreen;
}

input:checked + label .area .handle {
  background-color: white;
  transform: translateX(calc(var(--width) - var(--handle-size)));
}

.disable-events {
  pointer-events: none !important;
  background-color: #e3e3e3 !important;
  color: #787878 !important;
  border: 2px solid #e3e3e3 !important;

  svg {
    color: #787878 !important;
  }
}

.align-item-center-div {
  display: flex;
  justify-content: center;
}

.interpreted-text {
  font-family: "Poppins";
  font-size: 0.9em;
  padding-top: 5px;
}

.anim_views {
  &-enter {
    opacity: 0.01;
  }

  &-enter-active {
    opacity: 0.01;
  }

  &-enter-done {
    opacity: 1;
    transition: opacity 500ms linear;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0.01;
    transition: opacity 500ms linear;
  }
}

.camrewind-button {
  margin-bottom: 20px;
  background-color: #425781;
  border: none;
  color: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  text-align: center;
  align-self: auto;

  img {
    width: 25px;
    margin-right: 2px;
  }

  @media (max-width: 1000px) {
    font-size: 0.8em;
  }
}

#homeView {
  .up-margin {
    margin-top: 0px !important;
  }
}

.forecaster-component-alert-container {
  width: 100%;
  padding: 10px;
  color: #646464;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16;

  strong{
    color: #000;
  }

  *{
    padding: 0;
    margin: 0;
  }

  @media(min-width: 1000px){
    padding: 10px 15px; 
  }
}

.switch-plans {
  justify-content: center !important;
  display: inline-flex;
  text-align: center;
  width: 100%;
  margin-bottom: -20px;
  position: static;
  bottom: 10px;
  user-select: none;

  span {
    font-size: 0.7em;
    margin: 30px 10px;
    font-weight: 600;
    color: #0d144f;
  }

  .primary-switch {
    margin: 0px 15px;
    margin-top: 13px;
  }

  input + label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 17px;
  background-color: #20365729;
  border-radius: 22px;
  cursor: pointer;
  margin-top: 20p;
  padding-top: 10p;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-sub {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-sub:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -13px;
  bottom: 11px;
  background-color: #004988;
  transition: 0.4s;
}

.font-hg {
  font-weight: 700 !important;
}

input:checked + .slider-sub {
  background-color: red;
}

input:focus + .slider-sub {
  box-shadow: 0 0 1px #ff674117;
}

input:checked + .slider-sub:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider-sub.round {
  border-radius: 34px;
}

.slider-sub.round:before {
  border-radius: 50%;
}

.not-show {
  transition: opacity 0.5s ease-out;
  opacity: 0;
  pointer-events: none;
}

.reduce-padding {
  @media (min-width: 1000px) {
    padding: 25px 130px !important;
  }
}

.spinner-button {
  width: 23px !important;
  height: 23px !important;
}

.no-favorite {
  text-align: center;
  margin-top: 46px;
  padding: 40px;
  color: #b6b6b6;
}

.svg-cam {
  width: 45px;
  height: 45px;
  padding: 1px;
  margin: 1px 15px 0px 0px;
}


.svg-no-cam {
  width: 30px;
  margin: 0px 15px 0px 4px;
}

.camera-tag {
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  font-size: 0.8em;
  padding: 2px 3px;
}

.target-tag {
  background: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
  transition: all 0.5s ease-out;
}

.normal-tag {
  background: #fff;
  border-color: #435881;
  color: #000 !important;
  transition: all 0.5s ease-out;
}

.tab-container {
  @media (min-width: 1000px) {
    display: flex;
    justify-content: center;

    ul {
      width: 100%;
    }
  }
}

.cam-link {
  @media (max-width: 1000px) {
    a {
      padding: 0.5rem 1rem 2rem 1rem;
      margin-bottom: 10px !important;
    }
  }
}

.spot-component-second-cam-button {
  @media (max-width: 1000px) {
    a {
      position: relative;
    }

    span {
      position: absolute;
      width: 70px;
      height: 25px;
      bottom: 5px;
      right: 0 !important;
      left: 0;
      margin: auto;
    }
  }
}

.margin-b-10 {
  margin-bottom: 10px;
}

.multicam-icon {
  width: 21px !important;
}

.filter-dropdown {
  div:nth-child(1) {
    height: 42px !important;
    display: flex;
    align-items: center;
  }

  span:nth-of-type(1) {
    right: 0 !important;
  }
}

.underline-link {
  width: 100%;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  font-size: 16px;
  color: #007bff;

  @media (max-width: 1000px) {
    margin: 30px 0px;
    text-align: center;
    margin-top: 0;
  }
}

.infoWindow {
  width: 300px;
  height: 150px;

  > span {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  h1 {
    color: rgba(0, 0, 0, 0.701);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  p {
    font-size: 14px;
    font-weight: 400;

    span {
      color: rgba(0, 0, 0, 0.701);
      font-weight: 600;
    }
  }

  .button-primary {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 150px;
    margin: 0;
  }
}

.gm-ui-hover-effect {
  display: none !important;
}

div[role="dialog"] {
  max-height: fit-content !important;
  max-width: fit-content !important;
}

.gm-svpc,
.gmnoprint[role="menubar"] {
  display: none !important;
}

.forecast-row {
  flex-wrap: nowrap !important;
  position: relative;
}

#favorite-list, #benefit-list {
  display: flex;
  min-width: 100%;
}

.spot-card-items-container {
  @media (min-width: 1000px) {
    flex: 0 0 33.33%;
    max-width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  flex: 0 0 300px;
}

.spot-card-items {
  width: 95%;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin: 15px 2.5%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;

  .spotContent {
    display: flex;
    flex-direction: column;
    width: calc(100% - 140px);
    text-align: left;
    padding: 0 20px;
    color: #000;

    span:nth-of-type(1) {
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #000;
    }
  }

  img {
    width: 140px;
    height: 80px;
    margin-left: 10px;
    border-radius: 15px;
    top: -10px;
    left: 15px;
  }

  @media (max-width: 999px) {
    img {
      width: 100px;
    }
    a {
      width: calc(100% - 100px);
    }
  }
}

.ant-popconfirm-buttons {
  display: flex;
  flex-direction: row-reverse !important;
}

.absolute {
  position: absolute;
}

.benefit-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.benefits-items-container {
  justify-content: center;
  align-items: flex-start;
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  flex: 0 0 300px;
}

.benefits-skeleton-height{
  @media (max-width: 1000px) {
    height: 350px !important;
  }
  height: 400px;
}

.benefits-skeleton-width{
  @media(max-width: 1000px){
    width: 80% !important;
  }
  width: 90% !important;
}

.visit-link{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  margin: 0 auto;
}

.qr-button{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: 0 auto;
}

.flip-container{
  .react-card-back{
    transform-style: initial;
  }
}

.benefits-items {
  @media (max-width: 1000px) {
    font-size: 14px;
    width: 100%;
    min-height: 480px;
  }

.qr-container{
  display:flex;
  align-items: center;
  justify-content: center;
}

  min-height: 540px;
  width: 100% ;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin: 15px 2.5%;
  display: block;
  align-items: center;
  padding: 10px;
  padding-bottom: 150px;
  position: relative;
  transition: height 0.4s linear; //look for a way to generate a smooth transition when the height changes (activate on click view more) (now it doesnt work!!!)


  a {
    display: flex;
    flex-direction: row;
    // width: calc(100% - 140px);
    text-align: center;

    @media (max-width: 1000px) {
      width: 75%;
    }
  }

  img {
    width: 65%;
  }
}

.premiumButton {
  border: none;
  color: #fff;
  background-color: #5db24a;
  padding: 10px 50px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Poppins;
  width: 40%;

  @media (max-width: 1000px) {
    width: 60%;
    padding: 12px;
  }
}

.benefits-title {
  @media (max-width: 1000px) {
    font-size: medium;
  }
}

.benefits-title-large {
  @media (max-width: 1000px) {
    font-size: x-large;
  }
}

.subtitle {
  color: #203656;
  font-family: Poppins;
  font-size: medium;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
}

.checkBoxTick{
  &:checked::before {
    content: "\2713";
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 16px;
    color: white;
  }
}

.camSkeleton{
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  margin-bottom: 10px;
}

.ios-premium-poup-container{
  padding: 20px;
  width: 90%;
  background: #fff;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
}

.ios-premium-poup-container button{
  font-size: 14px !important;
}

.close-ios-premium-popup{
  width: 90%;
  text-align: center;
  border: none;
  border-radius: 8px;
  background: #004989;
  color: white;
  height: 50px;
}

.link-normalize:hover {
  text-decoration: none !important;
  color: #007bff !important;
}

.circular-point{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  bottom: 3px;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;
  background: #d01322;
}

.collapse-container{
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.collapse-icon {
  width: 18px !important;
  height: auto !important;  
}

.normalize-button{
  border: none;  
  background: #fff;
}

.save-the-waves-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.save-the-waves-button {
  border-radius: 8px;
  background-color: rgb(66, 87, 129);
  color: #fff;
  height: 40px;
  padding: 7px 15px;
  display: flex;
  gap: 10px;
  align-items: center;

  img{
    height: 100%;
  }
}

.save-the-waves-button:hover {
  text-decoration: none !important;
  color: #fff !important;
}